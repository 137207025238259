import { ReactNode } from 'react';
import cube from '~/assets/cube.svg';

type Props = {
  children: ReactNode;
  footer?: ReactNode;
  imageSubText?: string;
};

export const SignInPanel = ({ footer, children, imageSubText }: Props) => {
  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-50 py-12 dark:bg-background-900 sm:px-6 lg:px-8">
      <div className="relative mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="min-h-[570px] bg-white p-14 dark:bg-darkMode-gray-dark rounded-[30px] shadow-classic">
          <div className="mb-9 flex flex-col items-center justify-center">
            <img
              className="mx-auto mb-3 h-[6.5rem] w-auto"
              src={cube}
              alt="Workflow"
            />
            {imageSubText && (
              <p className="text-center text-base text-darkMode-gray-light/70">
                {imageSubText}
              </p>
            )}
          </div>
          {children}
        </div>
        {footer && (
          <div className="px-4 py-6 sm:px-10 absolute w-full">{footer}</div>
        )}
      </div>
    </div>
  );
};
